export class CustomEventListener {
  private subs: { [key: string]: { target: EventTarget; openState: boolean } };

  constructor() {
    this.subs = {};
  }

  addSub(eventName: string) {
    const target = new EventTarget();
    const context = this;

    if (this.subs[eventName]) {
      throw new SubError(`Sub with name: ${eventName} is not available.`);
    }
    this.subs[eventName] = { target: target, openState: false };
    return {
      open: () => {
        if (!this.subs[eventName]) {
          throw new ChannelError(`Sub with name: ${eventName} was deleted.`);
        }

        if (this.subs[eventName].openState) {
          throw new OpenChannelError("Channel already opened");
        }
        target.dispatchEvent(new CustomEvent("open"));

        this.subs[eventName].openState = true;
      },
      close: () => {
        if (!this.subs[eventName]) {
          throw new ChannelError(`Sub with name: ${eventName} was deleted.`);
        }

        if (!this.subs[eventName].openState) {
          throw new CloseChannelError(`Channel ${eventName} already closed`);
        }

        target.dispatchEvent(new CustomEvent("close"));
        this.subs[eventName].openState = false;
      },
      remove: function () {
        if (!context.subs[eventName]) {
          throw new ChannelError(`Channel with name: ${eventName} not found`);
        }
        if (context.subs[eventName].openState) {
          this.close();
        }
        delete context.subs[eventName];
      },
      update: (data?: any) => {
        if (!this.subs[eventName]) {
          throw new ChannelError(`Channel with name: ${eventName} not found`);
        }

        if (!this.subs[eventName].openState) {
          throw new OpenChannelError(`Channel with name: ${eventName} not opened`);
        }

        target.dispatchEvent(
          new CustomEvent<any>("data", {
            detail: data,
          })
        );
      },
    };
  }

  subscribe(eventName: string, callback: (type: string, message?: any) => void) {
    const channel = this.subs[eventName];
    if (!channel) {
      throw new Error(`Sub with name: ${eventName} not found in subs array`);
    }

    const openHandler = () => callback("open");
    const closeHandler = () => callback("close");
    const dataHandler = (e: CustomEvent<any>) => {
      callback("data", e.detail);
    };

    channel.target.addEventListener("open", openHandler);
    channel.target.addEventListener("close", closeHandler);
    channel.target.addEventListener("data", dataHandler as EventListenerOrEventListenerObject);

    if (channel.openState) {
      openHandler();
    }
    const unsubscribe = () => {
      channel.target.removeEventListener("open", openHandler);
      channel.target.removeEventListener("close", closeHandler);
      channel.target.removeEventListener("data", dataHandler as EventListenerOrEventListenerObject);
    };
    return unsubscribe;
  }
}

export class OpenChannelError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, OpenChannelError.prototype);
    this.name = "OpenChannelError";
  }
}

export class CloseChannelError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, CloseChannelError.prototype);
    this.name = "CloseChannelError";
  }
}

export class ChannelError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, ChannelError.prototype);
    this.name = "ChannelError";
  }
}

export class SubError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, SubError.prototype);
    this.name = "SubError";
  }
}
