// @ts-ignore
import kmlParser from "js-kml-parser";
import axios from "axios";

export const KmlParse = async (fileUrl: string): Promise<any | null> => {
  var response = await axios.get(fileUrl);

  const geojson = kmlParser.toGeoJson(response.data);
  if(geojson.features.length === 0){
    return null;
  }
  const coordinates = geojson.features[0].geometry.coordinates;

  const date = 0;
  for (let i = 0; i < coordinates.length; i++) {
    coordinates[i].push(date);
  }

  return geojson;
};
