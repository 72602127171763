import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import cn from "classnames";
import SensitiveContent from "../../../images/eye-slash.svg";
import { AdaptiveModal } from "../../../components/adaptiveModal";
import styles from "./event.module.css";
import { RootState } from "../../../store/index";
import { ContentComponentFactory } from "./contentFactory";
import { EventProperties } from "../../../interfaces/events";
import { blackOrWhite, colorRangeManager, generateKey, getDate, hexToRGB } from "../../../utils/tools";
import { useLocation, useNavigate, useParams } from "react-router";
import { createCloseModalEventAction } from "./reducer";
import { violenceLevels } from "../../../constants/eventDataTypes";

interface CategoryItemProps {
  categoryName: string;
  uniqKey: string;
}
interface CloseButtonInterface {
  closeModal: () => void;
}
interface ContentProps {
  contentComponent: any;
  eventData: EventProperties;
  close: () => void;
}
interface SensitiveWarningProps {
  onView: () => void;
}

export const EventModal = () => {
  const { id } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [contentComponent, setContentComponent] = useState<any>();
  const [eventData, setEventData] = useState<EventProperties>();
  const { searchEngine } = useSelector((state: RootState) => state.searchEngine);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    if (!id) {
      return;
    }

    let eventData = searchEngine.getEvent(id);
    if (!eventData) {
      return;
    }

    const url = eventData.url;
    const obj = ContentComponentFactory.create(url);
    setContentComponent(obj.getComponent());

    setEventData(eventData);
    setOpen(true);
  }, [id, searchEngine.ready]);

  const close = () => {
    setOpen(false);
    navigate(`/${search}`);
    dispatch(createCloseModalEventAction());
  };

  return (
    <AdaptiveModal open={open} close={close}>
      {eventData && <ContentContainer eventData={eventData} contentComponent={contentComponent} close={close} />}
    </AdaptiveModal>
  );
};

const CloseButton = ({ closeModal }: CloseButtonInterface) => {
  return (
    <div data-testid="close-modal-button" className={styles.closeButton} onClick={closeModal}>
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.00048 4.05732L8.30048 0.757324L9.24315 1.69999L5.94315 4.99999L9.24315 8.29999L8.30048 9.24266L5.00048 5.94266L1.70048 9.24266L0.757812 8.29999L4.05781 4.99999L0.757812 1.69999L1.70048 0.757324L5.00048 4.05732Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

const ContentContainer = ({ contentComponent, eventData, close }: ContentProps) => {
  const sensitiveContentStep = 3;
  let date = getDate(eventData.verifiedDate);
  const violenceLevelObj = violenceLevels[eventData.violenceLevel];
  const [disableScrollContent, setDisableScrollContent] = useState<boolean>(
    eventData.violenceLevel >= sensitiveContentStep
  );

  const viewSensitiveContent = () => {
    setDisableScrollContent(false);
  };

  return (
    <div className={styles.container} data-testid={`event-modal-${eventData.uniqueIndex}`}>
      <div className={cn(styles.contentContainer, disableScrollContent && styles.disableScroll)}>
        {contentComponent}
        {eventData.violenceLevel >= sensitiveContentStep && <SensitiveContentWarning onView={viewSensitiveContent} />}
      </div>
      <div className={styles.expandContainer}>
        <div className={styles.criteriaContainer}>
          <div className={styles.criteriaInfo}>
            <time className={styles.time} dateTime={date.attrDateTime}>
              {date.textDateTime}
            </time>
            <div
              style={{
                background: violenceLevelObj.color,
                color: blackOrWhite(...hexToRGB(violenceLevelObj.color)),
                padding: violenceLevelObj.padding,
              }}
              className={styles.violenceLevel}
            >
              Graphic Content Level: {violenceLevelObj.name}
            </div>
            <div className={styles.entry}>Entry: {eventData.id}</div>
          </div>
          <p className={styles.text}>Description: {eventData.description}</p>
        </div>
        <div className={styles.categories}>
          <div className={styles.categoriesContainer}>
            {eventData.categories.map((item, index) => (
              <CategoryItem categoryName={item} uniqKey={generateKey("categoryItem")} key={index} />
            ))}
          </div>
        </div>
        <span className={styles.divideLine}></span>
        <GridContainer eventData={eventData} />
      </div>
      <CloseButton closeModal={close} />
    </div>
  );
};

const CategoryItem = ({ categoryName, uniqKey }: CategoryItemProps) => {
  const categoryColor = colorRangeManager.getCategoryColor(categoryName);

  return (
    <div className={styles.categoryItem} key={uniqKey}>
      <span
        className={styles.categoryItemColorDot}
        style={{
          background: categoryColor,
        }}
      ></span>
      <span>{categoryName}</span>
    </div>
  );
};

const SensitiveContentWarning = ({ onView }: SensitiveWarningProps) => {
  const container = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(true);

  const close = () => {
    setOpen(false);
    onView();
  };

  const view = () => {
    if (container.current) {
      const el = container.current;
      el.style.opacity = "0";
      setTimeout(close, 300);
      return;
    }
    close();
  };

  return (
    <>
      {open ? (
        <div ref={container} role="sensitive-content-warning" className={cn(styles.sensitiveWarningContainer)}>
          <div className={styles.sensitiveWarningBlock}>
            <img src={SensitiveContent} alt="Sensitive content" className={styles.sensitiveIcon} />
            <span className={styles.sensitiveBlockMessage}>
              This content has been hidden because it may contain violent content. You can click here to view the
              source.
            </span>
            <Button role="close-sensitive-content-warning" className={styles.sensitiveBlockPreviewBtn} onClick={view}>
              View content
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};

const GridContainer = ({ eventData }: { eventData: EventProperties }) => {
  const itemInfo = (item: string | undefined) => {
    return item ? item : "-";
  };

  return (
    <div className={styles.infoGridContainer}>
      <div className={styles.itemContainer}>
        <span className={styles.typeOfProperty}>Link</span>
        <a
          className={cn(styles.mainInfo, styles.mainInfoLink)}
          rel="noreferrer"
          href={eventData.url}
          target="_blank"
        >
          {eventData.url}
        </a>
      </div>
      <div className={styles.itemContainer}>
        <span className={styles.typeOfProperty}>Coordinates</span>
        <span className={styles.mainInfo}>{`${eventData.lat}, ${eventData.long}`}</span>
      </div>
      <div className={styles.itemContainer}>
        <span className={styles.typeOfProperty}>Type of area Affected</span>
        <span className={styles.mainInfo}>
          {itemInfo(eventData.areaTypeAffected)}
        </span>
      </div>
      <div className={styles.itemContainer}>
        <span className={styles.typeOfProperty}>Country</span>
        <span className={styles.mainInfo}>
          {itemInfo(eventData.country)}
        </span>
      </div>
      <div className={styles.itemContainer}>
        <span className={styles.typeOfProperty}>Oblast / Province</span>
        <span className={styles.mainInfo}>
          {itemInfo(eventData.province)}
        </span>
      </div>
      <div className={styles.itemContainer}>
        <span className={styles.typeOfProperty}>Town / City</span>
        <span className={styles.mainInfo}>
          {itemInfo(eventData.city)}
        </span>
      </div>
      <div className={styles.itemContainer}>
        <span className={styles.typeOfProperty}>Geolocation</span>
        <a
          className={cn(styles.mainInfo, styles.mainInfoLink, !eventData.geolocUrl && styles.disableLinkMode)}
          rel="noreferrer"
          href={eventData.geolocUrl ? eventData.geolocUrl : undefined}
          target="_blank"
        >
          {itemInfo(eventData.geolocUrl)}
        </a>
      </div>
      <div className={styles.itemContainer}>
        <span className={styles.typeOfProperty}>Credit</span>
        <span role="credit" className={styles.mainInfo}>
          {itemInfo(eventData.credit)}
        </span>
      </div>
      <div className={styles.itemContainer}>
        <span className={styles.typeOfProperty}>Entry</span>
        <span role="entry" className={styles.mainInfo}>
          {itemInfo(eventData.id)}
        </span>
      </div>
    </div>
  );
};
