import { EDITOR_MODE_DATA_LAYER_ID, EVENTS_DATA_LAYER_ID } from "../../../../keplergl/keplerMapUtils";
import store from "../../../../../store";
import { CustomEventListener } from "../../../../../utils/channel";
import { compareArrays } from "../../../../../utils/tools";

export const UPDATE_KEPLER_FILTERED_INDEXES = "UPDATE_KEPLER_FILTERED_INDEXES";
export const UPDATE_EDITOR_MODE_INDEXES = "UPDATE_EDITOR_MODE_INDEXES";

export class KeplerDataListener {
  private currentLayerId: string = EVENTS_DATA_LAYER_ID;
  public listener: CustomEventListener;
  private onlyMapFrameEventsTarget: any;
  private editorModeSelectedEventsTarget: any;

  constructor() {
    this.listener = new CustomEventListener();
    this.onlyMapFrameEventsTarget = this.listener.addSub(UPDATE_KEPLER_FILTERED_INDEXES);
    this.onlyMapFrameEventsTarget.open();

    this.editorModeSelectedEventsTarget = this.listener.addSub(UPDATE_EDITOR_MODE_INDEXES);
    this.editorModeSelectedEventsTarget.open();
  }

  public getKeplerFilteredIndexes(state: any, layerId: string) {
    if (!state.map || !state.map.visState.datasets[layerId]) {
      return [];
    }
    this.currentLayerId = layerId;

    if (this.currentLayerId === EDITOR_MODE_DATA_LAYER_ID) {
      return state.map.visState.datasets[layerId].filteredIndex;
    } else {
      return state.map.visState.datasets[layerId].filteredIndexForDomain;
    }
  }

  public startListen() {
    let lastIndexes: number[] = [];

    return store.subscribe(() => {
      const state = store.getState();

      const keplerSelector = state.keplerGl;
      const indexes = this.getKeplerFilteredIndexes(keplerSelector, this.currentLayerId);

      if (!compareArrays<number>(lastIndexes, indexes)) {
        switch (this.currentLayerId) {
          case EDITOR_MODE_DATA_LAYER_ID:
            this.editorModeSelectedEventsTarget.update(indexes);
            break;
          case EVENTS_DATA_LAYER_ID:
            this.onlyMapFrameEventsTarget.update(indexes);
            break;
        }
      }

      lastIndexes = indexes;
    });
  }
}
