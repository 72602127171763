export default class CacheManager {
  private cacheName: string;
  private static cacheInstance: CacheManager | null = null;

  private constructor(cacheName: string) {
    this.cacheName = cacheName;
  }

  public static create(cacheName: string) {
    if (!this.cacheInstance) {
      this.cacheInstance = new CacheManager(cacheName);
    }
    return this.cacheInstance;
  }

  public async get(url: string): Promise<Response | undefined> {
    const cacheObj = await caches.open(this.cacheName);

    const req = new Request(url);
    return new Promise<Response | undefined>((resolve) => {
      cacheObj
        .match(req)
        .then((response: Response | undefined) => {
          resolve(response);
        })
        .catch(() => {
          resolve(undefined);
        });
    });
  }

  public async removeAll(): Promise<boolean> {
    const cacheObj = await caches.open(this.cacheName);
    const keys = await cacheObj.keys();
    keys.forEach((key) => {
      cacheObj.delete(key);
    });
    return true;
  }

  public async put(url: string, value: Uint8Array): Promise<void> {
    const cacheObj = await caches.open(this.cacheName);

    const req = new Request(url);

    const blob = new Blob([value], { type: "text/plain" });
    const init = { status: 200 };
    const res = new Response(blob, init);

    return cacheObj.put(req, res);
  }
}
