import { AnyAction, Store } from "redux";
import { taskReducerActions } from "../constants/actionTypes";
import { RootState } from "./index";

type taskFunc = () => AnyAction | null;
interface TaskReducerInterface {
  task: taskFunc | null;
}
const initialState: TaskReducerInterface = {
  task: null,
};

export const taskReducer = (state: TaskReducerInterface = initialState, action: AnyAction) => {
  switch (action.type) {
    case taskReducerActions.CLEAR:
      return {
        task: null,
      };
    default:
      return state;
  }
};

export const startListenTasks = (store: Store<RootState, any>) => {
  store.subscribe(() => {
    const { taskReducer } = store.getState();
    if (!taskReducer.task) {
      return;
    }
    const action = taskReducer.task();

    store.dispatch(createClearTaskReducerAction());
    store.dispatch(action);
  });
};

export const registerTask = (state: any, func: () => AnyAction) => {
  return {
    ...state,
    taskReducer: {
      task: func,
    },
  };
};

const createClearTaskReducerAction = () => {
  return {
    type: taskReducerActions.CLEAR,
  };
};
