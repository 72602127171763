import { AnyAction } from "redux";
import { editorModeActions } from "../../../constants/actionTypes";

interface EditorModeReducerStateInterface {
  editorMode: boolean;
  feature: any | null;
  featureId: string | null;
}

const initialValue: EditorModeReducerStateInterface = {
  editorMode: false,
  feature: null,
  featureId: null,
};

export const editorModeMapReducer = (
  state: EditorModeReducerStateInterface = initialValue,
  action: AnyAction
): EditorModeReducerStateInterface => {
  switch (action.type) {
    case editorModeActions.SET_EDITOR_MODE:
      return { ...state, editorMode: action.payload };
    default:
      return state;
  }
};

export const setEditorModeActionCreator = (enable: boolean) => {
  return {
    type: editorModeActions.SET_EDITOR_MODE,
    payload: enable,
  };
};
