import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes as KeplerActionTypes } from "kepler.gl/actions";
import { Popover, Tooltip } from "antd";
import cn from "classnames";
import Eye from "../../../images/eye.svg";
import PolygonIcon from "../../../images/polygon.svg";
import CrossedEye from "../../../images/crossed-eye.svg";
import Polygon from "../../../images/draw-polygon.svg";
import SelectIcon from "../../../images/editor-select.svg";
import RectangleIcon from "../../../images/rectangle-icon.svg";
import { RootState } from "../../../store";
import { ActiveListItem } from "../../../components/activeList";
import styles from "../keplergl.module.css";

type EDITOR_MODE = "EDIT_VERTEX" | "DRAW_RECTANGLE" | "DRAW_POLYGON";

const select = (keplerSelector: any) => {
  return keplerSelector?.map?.visState?.editor;
};

export const EditorModePopup: React.FC<ActiveListItem> = ({ onActive, onInActive, active }: ActiveListItem) => {
  const [popupOpen, setPopupOpen] = useState<boolean>(active);
  const [hide, setHide] = useState<boolean>(false);
  const [toolTipOpen, setToolTipOpen] = useState<boolean>(false);
  const keplerSelector: any = useSelector((state: RootState) => state.keplerGl);
  const [selected, setSelected] = useState<EDITOR_MODE>("DRAW_RECTANGLE");
  const dispatch = useDispatch();

  useEffect(() => {
    if (popupOpen && !active) {
      dispatchEditorMode();
    }
    setPopupOpen(active);
  }, [active]);

  useEffect(() => {
    const editor = select(keplerSelector);
    if (editor) {
      setSelected(editor.mode);
    }
  }, [keplerSelector]);

  const toggleEditorVisibility = () => {
    setHide(!hide);
    dispatch({
      type: KeplerActionTypes.TOGGLE_EDITOR_VISIBILITY,
      payload: { type: KeplerActionTypes.TOGGLE_EDITOR_VISIBILITY, meta: { _id_: "map" } },
      meta: { _forward_: "@redux-forward/FORWARD", _addr_: "@@KG_MAP" },
    });
  };

  const chooseEditorMode = (mode: EDITOR_MODE) => {
    dispatch({
      type: KeplerActionTypes.SET_EDITOR_MODE,
      payload: { type: KeplerActionTypes.SET_EDITOR_MODE, mode: mode, meta: { _id_: "map" } },
      meta: { _forward_: "@redux-forward/FORWARD", _addr_: "@@KG_MAP" },
    });
  };

  const toggleEditorMode = () => {
    if (!popupOpen) {
      onActive();
      setToolTipOpen(false);
    } else {
      onInActive();
      setToolTipOpen(true);
    }
    setPopupOpen(!popupOpen);
    dispatchEditorMode();
  };

  const dispatchEditorMode = () => {
    dispatch({
      type: KeplerActionTypes.TOGGLE_MAP_CONTROL,
      payload: {
        type: KeplerActionTypes.TOGGLE_MAP_CONTROL,
        payload: { panelId: "mapDraw", index: 0 },
        meta: { _id_: "map" },
      },
      meta: { _forward_: "@redux-forward/FORWARD", _addr_: "@@KG_MAP" },
    });
  };

  const tooltipOpenChange = (open: boolean) => {
    if (popupOpen) {
      setToolTipOpen(false);
      return;
    }
    setToolTipOpen(open);
  };

  return (
    <Popover
      content={
        <div className={styles.editorPopup}>
          <div
            onClick={() => {
              setSelected("EDIT_VERTEX");
              chooseEditorMode("EDIT_VERTEX");
            }}
            className={cn(styles.editorItem, selected === "EDIT_VERTEX" && styles.selectedEditorItem)}
          >
            <img src={SelectIcon} alt="Select area" />
            <span>Select</span>
          </div>
          <div
            onClick={() => {
              setSelected("DRAW_RECTANGLE");
              chooseEditorMode("DRAW_RECTANGLE");
            }}
            className={cn(styles.editorItem, selected === "DRAW_RECTANGLE" && styles.selectedEditorItem)}
          >
            <img src={RectangleIcon} alt="Draw area" />
            <span>Rectangle</span>
          </div>
          <div
            onClick={() => {
              setSelected("DRAW_POLYGON");
              chooseEditorMode("DRAW_POLYGON");
            }}
            className={cn(styles.editorItem, selected === "DRAW_POLYGON" && styles.selectedEditorItem)}
          >
            <img src={PolygonIcon} alt="Draw polygon" />
            <span>Polygon</span>
          </div>
          <div onClick={toggleEditorVisibility} className={cn(styles.editorItem)}>
            <img src={!hide ? Eye : CrossedEye} alt={!hide ? "Hide" : "Show"} />
            <span>{!hide ? "Hide" : "Show"}</span>
          </div>
        </div>
      }
      title={null}
      trigger="click"
      placement="left"
      open={popupOpen}
    >
      <Tooltip open={toolTipOpen} onOpenChange={tooltipOpenChange} placement="left" title="Draw on map">
        <button onClick={toggleEditorMode} className={cn(styles.keplerMapButton, styles.keplerEditorModeBtn)}>
          <img src={Polygon} alt="Editor mode toggle" />
        </button>
      </Tooltip>
    </Popover>
  );
};
