import { DateRange, FiltersData } from "../../../../interfaces/filters";
import { categoryList, sectorAffected } from "../variables";

const isValidDate = (date: any): boolean => {
  return date.toString() !== "Invalid Date";
};

const validateList = (list: string[], exampleList: string[]): string[] => {
  let readyList = [];
  for (let i = 0; i < list.length; i++) {
    const found = exampleList.find((item) => list[i] === item);
    if (found) {
      readyList.push(list[i]);
    }
  }

  return readyList;
};

const parseDateRange = (dateRange: string | null): DateRange => {
  if (!dateRange) {
    return null;
  }
  const [startDate, endDate] = dateRange.split(",");

  const start = new Date(Number(startDate));
  const end = new Date(Number(endDate));

  if (isValidDate(start) && isValidDate(end)) {
    return [start, end];
  }

  return null;
};

const parseList = (list: string | null, exampleList: string[]): string[] => {
  return list ? validateList(list.split(","), exampleList) : [];
};

const parseBoolean = (val: string | null): boolean => {
  return val === "true";
};

const parseString = (val: string | null): string => {
  return val ? val : "";
};

export const getSearchParams = (urlParams: URLSearchParams): FiltersData => {
  const chosenOption = urlParams.get("chosenOption");
  const categories = urlParams.get("categories");
  const sectors = urlParams.get("sectorAffected");
  const dateRange = urlParams.get("dateRange");
  const frameEvents = urlParams.get("onlyEventsMapFrame");
  const query = urlParams.get("query");

  return {
    categories: parseList(categories, categoryList),
    sectorAffected: parseList(sectors, sectorAffected),
    dateRange: parseDateRange(dateRange),
    onlyEventsMapFrame: parseBoolean(frameEvents),
    chosenOption: parseString(chosenOption),
    query: parseString(query),
  };
};

const prepareListToSearchParam = (arr: string[]): string => {
  return arr.join(",");
};

const prepareDateRangeToSearchParam = (dateRange: DateRange): string => {
  return dateRange ? dateRange.map((item) => String(item.getTime())).join() : "";
};

const prepareBooleanToSearchParam = (val: boolean): string => {
  return String(val);
};

export const getSearchParamsFromFilters = (filters: FiltersData): Record<string, string> => {
  return {
    query: filters.query,
    chosenOption: filters.chosenOption,
    categories: prepareListToSearchParam(filters.categories),
    sectorAffected: prepareListToSearchParam(filters.sectorAffected),
    dateRange: prepareDateRangeToSearchParam(filters.dateRange),
    onlyEventsMapFrame: prepareBooleanToSearchParam(filters.onlyEventsMapFrame),
  };
};
