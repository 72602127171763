import React, { useEffect, useState, useMemo } from "react";
import { parse } from "date-fns";
import { RangeValue } from "rc-picker/lib/interface";
import { throttle } from "lodash";
import moment, { Moment } from "moment/moment";
import { DatePicker } from "antd";
import { useSelector } from "react-redux";
import styles from "../search.module.css";
import { DateRange } from "../../../../interfaces/filters";
import { getKeplerSelector } from "../../../../utils/tools";
import store, { RootState } from "../../../../store";
import { CustomEventListener } from "../../../../utils/channel";
import { DefaultKeplerFilters } from "../../../keplergl/keplerMapUtils";
import { useSearchParams } from "react-router-dom";

interface CustomRangeDatePickerProps {
  onChange: (date: DateRange) => void;
  defaultDateRange: DateRange;
}
type DatePickerRange = [Moment, Moment] | null;
const dateFormat = "DD/MM/YYYY";

const DATE_UPDATE = "DATE_UPDATE";
const listener = new CustomEventListener();
const keplerDateTarget = listener.addSub(DATE_UPDATE);
keplerDateTarget.open();

let lastDate: [number, number] = [0, 0];
store.subscribe(() => {
  const storage = store.getState();

  const keplerSelector = storage?.keplerGl;
  if (!keplerSelector?.map) {
    return;
  }
  if (keplerSelector.map.visState.filters.length === 0) {
    return;
  }

  const dateRange = keplerSelector.map.visState.filters[DefaultKeplerFilters.publicFilters.VERIFIED_DATA_ID]?.value;
  if (!dateRange) {
    keplerDateTarget.update([Infinity, Infinity]);
    return;
  }

  const [startDate, endDate] = dateRange;
  if (lastDate[0] !== startDate || endDate !== lastDate[1]) {
    keplerDateTarget.update([startDate, endDate]);
  }

  lastDate = [startDate, endDate];
});

const getDateRangeSearchParam = (range: DatePickerRange) => {
  if (!range) {
    return "";
  } else {
    const start = range[0].toDate().getTime();
    const end = range[1].toDate().getTime();

    return { dateRange: `${start},${end}` };
  }
};

const getSearchParamsDct = (searchParams: URLSearchParams): Record<string, string> => {
  const dct: Record<string, string> = {};
  // @ts-ignore
  for (let key of searchParams.keys()) {
    // @ts-ignore
    dct[key] = searchParams.get(key);
  }
  return dct;
};

export const CustomRangeDatePicker = ({ onChange, defaultDateRange }: CustomRangeDatePickerProps) => {
  const [value, setValue] = useState<DatePickerRange>(
    defaultDateRange ? [moment(defaultDateRange[0], dateFormat), moment(defaultDateRange[1], dateFormat)] : null
  );
  const { initialStateChanged } = useSelector((state: RootState) => state.searchEngine);
  const [, setSearchParams] = useSearchParams();

  const setDateRangeFromKepler = useMemo(() => {
    let start: number;
    let end: number;

    const setDateRange = throttle(() => {
      if (isNaN(new Date(start).getTime())) {
        setValue(null);
        return;
      }
      setValue([moment(new Date(start), dateFormat), moment(new Date(end), dateFormat)]);
    }, 150);
    return (startDate: number, endDate: number) => {
      start = startDate;
      end = endDate;
      setDateRange();
    };
  }, []);

  useEffect(() => {
    const unsubscribe = listener.subscribe(DATE_UPDATE, (type: string, message: any) => {
      if (type !== "data") {
        return;
      }

      const [startDate, endDate] = message;
      if (!initialStateChanged) {
        return;
      }
      setDateRangeFromKepler(startDate, endDate);
    });

    return () => {
      unsubscribe();
    };
  }, [initialStateChanged, setDateRangeFromKepler]);

  useEffect(() => {
    setSearchParams((lastSearchParams: URLSearchParams) => {
      const searchParams = getSearchParamsDct(lastSearchParams);
      return {
        ...searchParams,
        ...getDateRangeSearchParam(value),
      };
    });
  }, [value]);

  const getDefaultDataRange = (): DateRange => {
    const keplerSelector = getKeplerSelector();
    const filters = keplerSelector?.map?.visState?.filters;
    if (!filters) {
      return null;
    }

    if (!filters[DefaultKeplerFilters.publicFilters.VERIFIED_DATA_ID]) {
      return null;
    }
    if (filters.length === 0 || filters[DefaultKeplerFilters.publicFilters.VERIFIED_DATA_ID].domain.length === 0) {
      return null;
    }

    const fullHistogram = filters[DefaultKeplerFilters.publicFilters.VERIFIED_DATA_ID].enlargedHistogram;
    const startDate = fullHistogram[0]["x0"];
    const endDate = fullHistogram[fullHistogram.length - 1]["x0"];

    return [new Date(startDate), new Date(endDate)];
  };

  const dateRangeOnChange = (date: RangeValue<Moment> | null, dateString: [string, string]) => {
    const dateRangeValue: DateRange =
      date !== null
        ? [parse(dateString[0], "dd/MM/yyyy", new Date()), parse(dateString[1], "dd/MM/yyyy", new Date())]
        : null;

    if (dateRangeValue) {
      setValue([moment(dateRangeValue[0], dateFormat), moment(dateRangeValue[1], dateFormat)]);
      onChange(dateRangeValue);
    } else {
      const defaultDateRange = getDefaultDataRange();
      if (defaultDateRange) {
        setValue([moment(defaultDateRange[0], dateFormat), moment(defaultDateRange[1], dateFormat)]);
        onChange(defaultDateRange);
      }
    }
  };

  return (
    <div className={styles.wrapperDatePicker}>
      <div className={styles.searchDatePickerBlock}>
        <DatePicker.RangePicker value={value} format={dateFormat} onChange={dateRangeOnChange} />
      </div>
    </div>
  );
};
