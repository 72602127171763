import React, { useEffect, useState } from "react";
import cn from "classnames";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { CaretRightOutlined } from "@ant-design/icons/lib";
import { Button, Checkbox, Popover, Row, Collapse } from "antd";
import { CaretDownOutlined, MinusOutlined } from "@ant-design/icons";
import styles from "../search.module.css";

const { Panel } = Collapse;

interface CheckBoxGroupProps {
  onChecked: (items: string[]) => void;
  name: string;
  placeholder: string;
  options: string[];
  checkedOptions?: string[];
}
interface CheckboxGroupInterface {
  checkedList: string[];
  onChangeCheckBox: (selected: CheckboxValueType[]) => void;
  checkAllOptions: () => void;
  unCheckAllOptions: () => void;
  options: string[];
  lastCheckedList: string[];
  apply: () => void;
}

const equalLists = (listOne: string[], listTwo: string[]): boolean => {
  if (listOne.length !== listTwo.length) {
    return false;
  }
  const ln = listOne.length;
  let counter = 0;
  for (let i = 0; i < ln; i++) {
    if (listOne[i] === listTwo[i]) {
      counter += 1;
    }
  }

  return counter === ln;
};

export const CheckboxGroup = ({ onChecked, name, placeholder, options, checkedOptions }: CheckBoxGroupProps) => {
  const [checkedList, setCheckedList] = useState<string[]>(checkedOptions || []);
  const [lastCheckedList, setLastCheckedList] = useState<string[]>(checkedOptions || []);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [checkedLength, setCheckedLength] = useState<number>(lastCheckedList.length);

  useEffect(() => {
    if (!checkedOptions) {
      return;
    }

    setCheckedLength(checkedOptions.length);
    setCheckedList(checkedOptions);
    setLastCheckedList(checkedOptions);
  }, [checkedOptions]);

  const onChangeCheckBox = (selected: CheckboxValueType[]) => {
    setCheckedList(selected.map((item) => item.toString()));
  };

  const unCheckAllOptions = () => {
    setCheckedList([]);
  };

  const checkAllOptions = () => {
    setCheckedList(options);
  };

  const apply = () => {
    setPopoverOpen(false);
    setCheckedLength(checkedList.length);
    setLastCheckedList(checkedList);
    onChecked(checkedList);
  };

  const handleOpenChange = (visible: boolean) => {
    setPopoverOpen(visible);
  };

  return (
    <>
      <Collapse
        className={cn(styles.mobileStyle, styles.checkBoxCollapse)}
        defaultActiveKey="1"
        expandIconPosition="end"
        expandIcon={(panelProps) => {
          return <CaretRightOutlined className={cn(styles.expandIcon, panelProps.isActive && styles.expandIconOpen)} />;
        }}
      >
        <Panel
          header={<span style={{ color: "white" }}>{checkedLength !== 0 ? name : placeholder}</span>}
          key="1"
          className={styles.checkBoxPanel}
        >
          <CheckBoxGroupInnerComponent
            checkedList={checkedList}
            onChangeCheckBox={onChangeCheckBox}
            checkAllOptions={checkAllOptions}
            unCheckAllOptions={unCheckAllOptions}
            options={options}
            lastCheckedList={lastCheckedList}
            apply={apply}
          />
        </Panel>
      </Collapse>
      <Popover
        className={styles.checkBoxGroupPopover}
        open={popoverOpen}
        overlayClassName={styles.checkBoxGroupPopupOverlay}
        onOpenChange={handleOpenChange}
        content={
          <CheckBoxGroupInnerComponent
            checkedList={checkedList}
            onChangeCheckBox={onChangeCheckBox}
            checkAllOptions={checkAllOptions}
            unCheckAllOptions={unCheckAllOptions}
            options={options}
            lastCheckedList={lastCheckedList}
            apply={apply}
          />
        }
        trigger="click"
        placement="bottomLeft"
      >
        <Button className={styles.categoriesCheckboxButton}>
          <div>
            <span className={cn(styles.greenCircle, checkedLength !== 0 && styles.greenCircleVisible)}>
              {checkedLength !== 0 && checkedLength}
            </span>
            {checkedLength !== 0 ? name : placeholder}
          </div>
          <CaretDownOutlined />
        </Button>
      </Popover>
    </>
  );
};

const CheckBoxGroupInnerComponent = ({
  checkedList,
  onChangeCheckBox,
  checkAllOptions,
  unCheckAllOptions,
  options,
  lastCheckedList,
  apply,
}: CheckboxGroupInterface) => {
  const checkAll = () => {
    if (options.length === checkedList.length) {
      unCheckAllOptions();
    } else {
      checkAllOptions();
    }
  };

  return (
    <Checkbox.Group value={checkedList} className={styles.checkBoxGroup} onChange={onChangeCheckBox}>
      <Row className={styles.markAllCategoriesLine}>
        <div className={styles.markAllCategoriesBlock}>
          <div onClick={checkAll} className={styles.markAllCategoriesMark}>
            <MinusOutlined />
          </div>
          <span className={styles.selectAll} onClick={checkAll}>
            {options.length === checkedList.length ? "Remove All" : "Select All"}
          </span>
        </div>
        <Button
          className={cn(
            styles.applyCheckedList,
            equalLists(lastCheckedList, checkedList) && styles.applyCheckedListClose
          )}
          onClick={apply}
        >
          Apply
        </Button>
      </Row>
      {options.map((key) => {
        return (
          <Row key={key} className={cn(styles.checkBoxGroupRow, styles.underline)}>
            <Checkbox key={key} value={key}>
              {key}
            </Checkbox>
          </Row>
        );
      })}
    </Checkbox.Group>
  );
};
