import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "../../pages/home/menu";
import logo from "../../images/logo.svg";
import style from "./header.module.css";

export const Header = () => {
  return (
    <div className={style.header}>
      <Link to="/">
        <img className={style.logo} src={logo} alt="C4ADS" />
      </Link>
      <Menu />
    </div>
  );
};
