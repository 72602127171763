import React, { useMemo } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { AnyAction } from "redux";
import { debounce } from "lodash";
import { ActionTypes } from "kepler.gl/actions";
import { RootState } from "../../store";
import { UPDATE_EDITOR_MODE_INDEXES } from "../home/events/packages/keplerDataListener";
import { compareArrays, getKeplerSelector } from "../../utils/tools";
import { DefaultKeplerFilters, EDITOR_MODE_DATA_LAYER_ID } from "./keplerMapUtils";
import { KeplerMapFilters } from "./packages/keplerFilter";
import { SearchEngine } from "../../utils/packages/searchEngine";
import { createProxyArray } from "../../utils/packages/proxyCreator";
import { keplerExtension } from "../../constants/actionTypes";
import { useEffectWithMemory } from "../../hooks/useEffectWithMemory";

interface TimelineManagerProps {
  mapBox: any | undefined;
  onOpenChange: (open: boolean) => void;
}
interface ReDrawerInterface {
  stop: () => void;
  update: () => void;
}

type dispatchFunc = (action: AnyAction) => void;
const timelineProperty = "Timeline of verified data";
let pushState = () => {};

export const TimelineManager = ({ mapBox, onOpenChange }: TimelineManagerProps) => {
  const editorModeMapSelector = useSelector((state: RootState) => state.editorModeMapReducer);
  const { searchEngine } = useSelector((state: RootState) => state.searchEngine);
  const keplerSelector = useSelector((state: RootState) => state.keplerGl);
  const dispatch = useDispatch();

  const filteredIndexes = useMemo(() => {
    return getKeplerFilteredIndexes(getKeplerSelector(), EDITOR_MODE_DATA_LAYER_ID);
  }, [keplerSelector]);

  useEffectWithMemory<number[]>(
    (save, getLast) => {
      if (!editorModeMapSelector.editorMode) {
        return;
      }

      const lastIndexes = getLast();
      save(filteredIndexes);
      if (!lastIndexes) {
        pushState();
        return;
      }

      if (!compareArrays(filteredIndexes, lastIndexes)) {
        pushState();
      }
    },
    [editorModeMapSelector, pushState]
  );

  useEffect(() => {
    if (!mapBox || !searchEngine.ready) {
      return;
    }
    let disableRedraw: () => void;
    if (!editorModeMapSelector.editorMode) {
      return;
    }

    TimelineReDrawer.enableRedraw(
      searchEngine,
      mapBox,
      EDITOR_MODE_DATA_LAYER_ID,
      UPDATE_EDITOR_MODE_INDEXES,
      dispatch,
      onOpenChange
    ).then((manage) => {
      disableRedraw = manage.stop;
      pushState = manage.update;

      pushState();
    });

    return () => {
      pushState = () => {};
      if (!disableRedraw) {
        return;
      }
      disableRedraw();
    };
  }, [editorModeMapSelector.editorMode, editorModeMapSelector.featureId, dispatch]);

  return <></>;
};

class TimelineReDrawer {
  private static currentFilename: string = "";
  private static dispatch: dispatchFunc;
  private static searchEngine: SearchEngine;
  private static mapBox: any;

  private constructor() {}

  static async enableRedraw(
    searchEngine: SearchEngine,
    mapBox: any,
    listenedLayerId: string,
    keplerDataListenerId: string,
    dispatch: dispatchFunc,
    onOpenChange: (open: boolean) => void
  ): Promise<ReDrawerInterface> {
    this.mapBox = mapBox;
    this.searchEngine = searchEngine;
    this.dispatch = dispatch;

    const keplerSelector = getKeplerSelector();
    const keplerFilteredIndexes = getKeplerFilteredIndexes(keplerSelector, listenedLayerId);
    let indexes: number[] = keplerFilteredIndexes;

    let drawData = await this.reDraw(keplerFilteredIndexes);
    if (!drawData) {
      return await this.enableRedraw(
        searchEngine,
        mapBox,
        listenedLayerId,
        keplerDataListenerId,
        dispatch,
        onOpenChange
      );
    }
    let redrawMetadata = drawData;

    batch(() => {
      this.disableMainTimelineFilters();
      this.enableRedrawTimeline(redrawMetadata.dataId, redrawMetadata.filterIndex);
    });

    const debounceFunc = debounce(() => {
      const lastDataId = redrawMetadata.dataId;
      this.reDraw(indexes).then((redrawData) => {
        if (indexes.length <= 2) {
          onOpenChange(false);
        } else {
          onOpenChange(true);
        }
        if (!redrawData) {
          return;
        }
        redrawMetadata.dataId = redrawData.dataId;

        batch(() => {
          this.dispatch(KeplerMapFilters.enlargeTimelineFilter(redrawMetadata.filterIndex));
          this.dispatch(KeplerMapFilters.setFilterDataId(redrawMetadata.filterIndex, redrawMetadata.dataId));
          this.dispatch(KeplerMapFilters.setFilterName(redrawMetadata.filterIndex, timelineProperty));
          this.dispatch(KeplerMapFilters.updateFilterAnimationSpeed(redrawMetadata.filterIndex, 0.5));

          this.removeDataset(lastDataId);
        });
      });
    }, 200);

    const oldDatasetId = redrawMetadata.dataId;
    return {
      stop: () => {
        const keplerSelector = getKeplerSelector();
        const filters = keplerSelector.map.visState.filters;

        batch(() => {
          let index = 0;
          if (
            filters.find((item: any, i: number) => {
              index = i;
              return item.dataId[0] === redrawMetadata.dataId;
            })
          ) {
            this.disableRedrawTimeline(index);
          }
          this.removeDataset(oldDatasetId);
          this.enableMainTimelineFilters();
        });
      },
      update: () => {
        const keplerSelector = getKeplerSelector();
        indexes = getKeplerFilteredIndexes(keplerSelector, listenedLayerId);
        debounceFunc();
      },
    };
  }

  private static async reDraw(indexes: number[]) {
    if (this.currentFilename !== "") {
      const id = findDatasetByFileName(`${this.currentFilename}.geojson`);
      this.removeDataset(id);
    }

    this.currentFilename = crypto.randomUUID();

    const [proxyArray, _] = createProxyArray<any>(this.searchEngine.proxyData, indexes);
    const { lat, lng } = this.mapBox.getCenter();

    const data = getGeojsonDataFromRowData(proxyArray, this.searchEngine.currentFields[timelineProperty], [lng, lat]);
    const blob = new Blob([JSON.stringify(data)]);
    const file = new File([blob], `${this.currentFilename}.geojson`);

    const promise = new Promise<boolean>((resolve) => {
      this.dispatch({
        type: keplerExtension.LOAD_POINTS_FILE,
        payload: {
          files: [file],
          onFinish: () => {
            resolve(true);
          },
        },
      });
    });
    await promise;

    const table = findDatasetByFileName(`${this.currentFilename}.geojson`);
    if (!table) {
      return;
    }
    const filterIndex = getLastFilterIndex() + 1;
    return {
      dataId: table.id,
      filterIndex,
    };
  }

  private static removeDataset(id: string) {
    this.dispatch({
      type: ActionTypes.REMOVE_DATASET,
      payload: { type: ActionTypes.REMOVE_DATASET, dataId: id, meta: { _id_: "map" } },
      meta: { _forward_: "@redux-forward/FORWARD", _addr_: "@@KG_MAP" },
    });
  }

  private static enableMainTimelineFilters() {
    const eventsTimelineFilter = DefaultKeplerFilters.publicFilters.VERIFIED_DATA_ID;
    const editorTimelineFilter = DefaultKeplerFilters.publicFilters.EDITOR_MODE_VERIFIED_DATA_ID;

    const keplerSelector = getKeplerSelector();
    const filters = keplerSelector.map.visState.filters;

    if (!filters[eventsTimelineFilter].enlarged) {
      this.dispatch(KeplerMapFilters.enlargeTimelineFilter(eventsTimelineFilter));
    }

    if (!filters[editorTimelineFilter].enlarged) {
      this.dispatch(KeplerMapFilters.enlargeTimelineFilter(editorTimelineFilter));
    }
  }

  private static disableMainTimelineFilters() {
    const eventsTimelineFilter = DefaultKeplerFilters.publicFilters.VERIFIED_DATA_ID;
    const editorTimelineFilter = DefaultKeplerFilters.publicFilters.EDITOR_MODE_VERIFIED_DATA_ID;

    const keplerSelector = getKeplerSelector();
    const filters = keplerSelector.map.visState.filters;

    if (filters[eventsTimelineFilter].enlarged) {
      this.dispatch(KeplerMapFilters.enlargeTimelineFilter(eventsTimelineFilter));
    }

    if (filters[editorTimelineFilter].enlarged) {
      this.dispatch(KeplerMapFilters.enlargeTimelineFilter(editorTimelineFilter));
    }
  }

  private static enableRedrawTimeline(dataId: string, filterId: number) {
    this.dispatch(KeplerMapFilters.addFilter(dataId));
    this.dispatch(KeplerMapFilters.setFilterName(filterId, timelineProperty));
  }

  private static disableRedrawTimeline(filterId: number) {
    this.dispatch(KeplerMapFilters.removeFilterActionCreator(filterId));
  }
}

const getKeplerFilteredIndexes = (state: any, layerId: string) => {
  if (!state.map || !state.map.visState.datasets[layerId]) {
    return [];
  }
  return state.map.visState.datasets[layerId].filteredIndex;
};

const getLastFilterIndex = (): number => {
  const keplerSelector = getKeplerSelector();
  return keplerSelector.map.visState.filters.length - 1;
};

const findDatasetByFileName = (filename: string) => {
  const keplerSelector = getKeplerSelector();

  const datasets = keplerSelector.map.visState.datasets;
  let foundDataset: any;
  for (let key in datasets) {
    if (datasets[key].label === filename) {
      foundDataset = datasets[key];
      break;
    }
  }

  return foundDataset;
};

const getGeojsonDataFromRowData = (rowsData: any[], timelineFieldIndex: number, pointOfCenter: [number, number]) => {
  const data: Record<string, any> = {
    type: "FeatureCollection",
    features: [],
  };
  for (let i = 0; i < rowsData.length; i++) {
    const item = rowsData[i];

    const itemData: any = {
      type: "Feature",
      geometry: { type: "Point", coordinates: pointOfCenter },
      properties: {
        fillColor: [255, 0, 0, 0],
        radius: 0,
      },
    };

    itemData.properties[timelineProperty] = item[timelineFieldIndex];
    data.features.push(itemData);
  }
  return data;
};
