import React, { useEffect, useRef, useState } from "react";
import styles from "../events.module.css";

type simpleFunc = () => void;
interface SwipeProps {
  target: HTMLElement | null;
  readyCallBack: (offset: number, callback: simpleFunc) => void;
  almostOpen: boolean;
  swipeLock: boolean;
}

const SWIPE_LAG = 75;
const MAX_SWIPE_TOP = 0.5; // This value in percentage means part of the height of the screen

const disableTransition = (target: HTMLElement) => {
  target.style.transition = "none";
};

const getCurrentMaxTop = (): number => {
  return (1 - MAX_SWIPE_TOP) * window.innerHeight;
};

const getCurrentClientY = (event: TouchEvent): number => {
  const touch = event.touches[0];
  return touch.clientY;
};

const getBlockTop = (target: HTMLElement, almostOpen: boolean): number => {
  const { top, height } = target.getBoundingClientRect();
  const currentHeight = almostOpen ? height * 0.25 : 0;
  return top + currentHeight;
};

const clearStyles = (target: HTMLElement) => {
  target.setAttribute("style", "");
};

const getNewBlockTop = (startY: number, currentY: number, maxTop: number, startTop: number): [number, number] => {
  const offset = currentY - startY;
  let newTop = startTop + offset;

  if (newTop < maxTop) {
    newTop = maxTop;
  }

  return [newTop, offset];
};

export const Swipe = ({ target, readyCallBack, almostOpen, swipeLock }: SwipeProps) => {
  const swiperBlock = useRef<HTMLDivElement>(null);
  const [currentZIndex, setCurrentZIndex] = useState<number>(1);

  useEffect(() => {
    setCurrentZIndex(almostOpen ? 1 : -1);
  }, [almostOpen]);

  useEffect(() => {
    if (!target || !swiperBlock?.current) {
      return;
    }

    swiperBlock.current.addEventListener("touchstart", touchStartHandler, { passive: true });
    return () => {
      if (!swiperBlock?.current) {
        return;
      }
      swiperBlock.current.removeEventListener("touchstart", touchStartHandler);
    };
  }, [target, almostOpen, swipeLock, swiperBlock]);

  const touchStartHandler = (event: TouchEvent) => {
    if (!target) {
      return;
    }
    const startY = getCurrentClientY(event);
    const maxTop = getCurrentMaxTop();
    disableTransition(target);

    const startTop = getBlockTop(target, almostOpen);
    let currentOffset = 0;

    const touchmove = (moveEvent: TouchEvent) => {
      const currentY = getCurrentClientY(moveEvent);

      const [newTop, offset] = getNewBlockTop(startY, currentY, maxTop, startTop);
      currentOffset = offset;

      if (!almostOpen && swipeLock) {
        touchend();
        return;
      }

      target.style.top = `${newTop}px`;
    };

    const touchend = () => {
      if (Math.abs(currentOffset) < SWIPE_LAG) {
        clearStyles(target);
      }

      if (almostOpen && Math.abs(currentOffset) >= SWIPE_LAG) {
        readyCallBack(currentOffset, () => clearStyles(target));
      } else if (!almostOpen && currentOffset > SWIPE_LAG) {
        readyCallBack(currentOffset, () => clearStyles(target));
      }

      target.removeEventListener("touchmove", touchmove as EventListenerOrEventListenerObject);
      target.removeEventListener("touchend", touchend);
    };
    target.addEventListener("touchmove", touchmove as EventListenerOrEventListenerObject, { passive: true });
    target.addEventListener("touchend", touchend, { passive: true });
  };

  return (
    <div
      ref={swiperBlock}
      className={styles.swipeBlock}
      style={{
        zIndex: currentZIndex,
      }}
    />
  );
};
