import React from "react";
import { render } from "react-dom";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { Integrations } from "@sentry/tracing";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./app";
import store from "./store";
import reportWebVitals from "./reportWebVitals";
import { startListenTasks } from "./store/taskReducer";

Sentry.init({
  dsn: "https://69911fe31055406f857bfbc661f4ce75@o295592.ingest.sentry.io/6761772",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

/*
function FallbackComponent() {
  return <div>An error has occurred</div>;
}
 */

/*
const myFallback = <FallbackComponent />;
// Alternatively:
// const myFallback = () => <FallbackComponent />;
 */

startListenTasks(store);
render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
