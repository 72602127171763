import React, { useState, useEffect } from "react";
import { Button, Popover } from "antd";
import cn from "classnames";
import styles from "../keplergl.module.css";
import GeoJsonModeIcon from "../../../images/geojson-mode.svg";
import HeatMapModeIcon from "../../../images/heatmap-mode.svg";
import PlusIcon from "../../../images/plus.svg";
import MinusIcon from "../../../images/minus.svg";
import CloseIcon from "../../../images/close-icon.svg";
import MapHubClassic from "../../../images/map-icons/map-hub-classic.png";
import MapHubDefault from "../../../images/map-icons/map-hub-default.png";
import MapHubEarth from "../../../images/map-icons/map-hub-earth.png";
import { LayerModes } from "../keplerMapUtils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { changeMapStyleActionCreator } from "../reducers/keplerMapStateReducer";

interface MapControllerProps {
  zoomIn: () => void;
  zoomOut: () => void;
  toggleLayerMode: () => void;
  layerMode: LayerModes;
}
export interface MapInfo {
  id: string;
  name: string;
  img: string;
  url: string;
  key: number;
}
interface ChooseMapStyleProps {
  currentMapKey: number;
  onChange: (mapCategory: string, mapInfo: MapInfo) => void;
  close: () => void;
}
interface Maps {
  mapHub: MapInfo[];
}
const MAP_STYLE_KEY = "MAP_STYLE_KEY";
const mapsData: Maps = {
  mapHub: [
    { key: 0, id: "0", name: "Default", img: MapHubDefault, url: "mapbox://styles/mapbox/light-v10" },
    { key: 1, id: "1", name: "Classic", img: MapHubClassic, url: "mapbox://styles/mapbox/streets-v11" },
    { key: 2, id: "2", name: "Earth", img: MapHubEarth, url: "mapbox://styles/mapbox/satellite-v9" },
  ],
};

const setMapStyleData = (payload: MapInfo) => {
  const data = JSON.stringify(payload);
  localStorage.setItem(MAP_STYLE_KEY, data);
};

const getMapStyleData = (): MapInfo | null => {
  const data = localStorage.getItem(MAP_STYLE_KEY);
  if (!data) {
    return null;
  }
  return JSON.parse(data);
};

export const MapController = ({ zoomIn, zoomOut, toggleLayerMode, layerMode }: MapControllerProps) => {
  const { currentMap } = useSelector((state: RootState) => state.keplerMapState);
  const [mapsOpen, setMapsOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const mapStyle = getMapStyleData();

    if (!mapStyle) {
      return;
    }
    dispatch(changeMapStyleActionCreator(mapStyle));
  }, []);

  useEffect(() => {
    setMapStyleData(currentMap);
  }, [currentMap]);

  const openMaps = () => {
    setMapsOpen(true);
  };
  const closeMaps = () => {
    setMapsOpen(false);
  };

  return (
    <div className={styles.mapControlsButtonsContainer}>
      <div className={styles.mapControlsButtons}>
        <Button
          className={styles.mapControlButton}
          onClick={toggleLayerMode}
          shape="circle"
          icon={
            layerMode === LayerModes.Geojson ? (
              <img src={HeatMapModeIcon} alt="heatmap" />
            ) : (
              <img src={GeoJsonModeIcon} alt="geojson" />
            )
          }
          size="large"
        />
        <Button
          className={styles.mapControlButton}
          onClick={zoomIn}
          shape="circle"
          icon={<img src={PlusIcon} alt="plus-zoom" />}
          size="large"
        />
        <Button
          className={styles.mapControlButton}
          onClick={zoomOut}
          shape="circle"
          icon={<img src={MinusIcon} alt="minus-zoom" />}
          size="large"
        />
      </div>
      <Popover
        placement="leftBottom"
        open={mapsOpen}
        onOpenChange={(visible: boolean) => {
          if (!visible) {
            closeMaps();
          }
        }}
        content={
          <ChooseMapStyle
            currentMapKey={currentMap.key}
            close={closeMaps}
            onChange={(mapCategory: string, mapItem: MapInfo) => {
              dispatch(changeMapStyleActionCreator(mapItem));
            }}
          />
        }
        trigger="click"
      >
        <div onClick={openMaps} className={styles.openMapsButton}>
          <img src={currentMap.img} alt="Open maps button" />
        </div>
      </Popover>
    </div>
  );
};

const ChooseMapStyle = ({ onChange, close, currentMapKey }: ChooseMapStyleProps) => {
  const chooseMap = (mapCategory: string, mapItem: MapInfo) => {
    onChange(mapCategory, mapItem);
  };

  return (
    <div className={styles.mapChanger}>
      <div className={styles.mapHub}>
        <div className={styles.mapHeader}>
          <span className={styles.mapHubTitle}>MapHub</span>
          <span className={styles.closeMapsBtn} onClick={close}>
            <img src={CloseIcon} alt="Close map style dialog" />
          </span>
        </div>
        <div className={styles.maps}>
          {mapsData.mapHub.map((item: MapInfo, index: number) => {
            return (
              <div
                onClick={() => {
                  chooseMap("mapHub", item);
                }}
                className={styles.mapCard}
                key={index}
              >
                <img
                  className={cn(styles.mapCardImg, item.key === currentMapKey && styles.chosenMap)}
                  src={item.img}
                  alt={item.name}
                />
                <span>{item.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
