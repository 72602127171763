import React, { useEffect, useState } from "react";
import { itemInArray } from "../../../utils/tools";
import { TwitterEmbed, TikTokEmbed, YouTubeEmbed, InstagramEmbed } from "react-social-media-embed";
import TelegramEmbed from "react-telegram-embed";
import styles from "./event.module.css";
import { Loading } from "../../../components/loading";

interface ComponentProps {
  url: string;
}
interface EmbedLoaderProps {
  loadingMessage?: string;
}

const EmbedLoader = ({ loadingMessage }: EmbedLoaderProps) => {
  return (
    <div className={styles.loadingWidget}>
      <div className={styles.loadingBlock}>
        <Loading />
        <span className={styles.loadingText}>{loadingMessage}</span>
      </div>
    </div>
  );
};

class ContentComponent {
  protected url: string; // I used "protected" property instead of private because i need to use this property in the child classes
  constructor(url: string) {
    this.url = url;
  }

  getComponent() {
    const ContentComponent = React.memo(({ url }: ComponentProps) => (
      <div>
        <a href={url} target="_blank" rel="noreferrer">
          Link to remote resource
        </a>
      </div>
    ));

    return <ContentComponent url={this.url} />;
  }
}

class TelegramContentComponent extends ContentComponent {
  getComponent() {
    const ContentComponent = ({ url }: ComponentProps) => {
      const [ready, setReady] = useState<boolean>(false);

      useEffect(() => {
        const messageHandler = (event: MessageEvent) => {
          if (event.origin === "https://t.me") {
            let data;
            try {
              data = JSON.parse(event.data);
            } catch {
              return;
            }

            if (data.event === "ready") {
              setReady(true);
              window.removeEventListener("message", messageHandler);
            }
          }
        };
        window.addEventListener("message", messageHandler);
        return () => {
          window.removeEventListener("message", messageHandler);
        };
      }, [ready]);

      return (
        <>
          <TelegramEmbed src={url} />
          {!ready && <EmbedLoader loadingMessage="Telegram post is loading" />}
        </>
      );
    };

    return <ContentComponent url={this.url} />;
  }
}

class TwitterContentComponent extends ContentComponent {
  getComponent() {
    const ContentComponent = React.memo(({ url }: ComponentProps) => (
      <TwitterEmbed embedPlaceholder={<EmbedLoader loadingMessage="Twitter post is loading" />} url={url} />
    ));
    return <ContentComponent url={this.url} />;
  }
}

class TikTokContentComponent extends ContentComponent {
  getComponent() {
    const ContentComponent = React.memo(({ url }: ComponentProps) => (
      <TikTokEmbed embedPlaceholder={<EmbedLoader loadingMessage="TikTok video is loading" />} url={url} width="100%" />
    ));
    return <ContentComponent url={this.url} />;
  }
}

class YoutubeContentComponent extends ContentComponent {
  getComponent() {
    const ContentComponent = React.memo(({ url }: ComponentProps) => (
      <YouTubeEmbed
        embedPlaceholder={<EmbedLoader loadingMessage="Youtube video is Loading" />}
        url={url}
        width="100%"
      />
    ));
    return <ContentComponent url={this.url} />;
  }
}

class InstagramContentComponent extends ContentComponent {
  getComponent() {
    const ContentComponent = React.memo(({ url }: ComponentProps) => (
      <InstagramEmbed
        embedPlaceholder={<EmbedLoader loadingMessage="Instagram post is loading" />}
        url={url}
        width="100%"
      />
    ));
    return <ContentComponent url={this.url} />;
  }
}

export class ContentComponentFactory {
  public static create(url: string): ContentComponent {
    const urlData = new URL(url);
    const pathname = urlData.hostname;

    if (pathname === "t.me") {
      return new TelegramContentComponent(url);
    } else if (pathname.indexOf("twitter.com") !== -1) {
      return new TwitterContentComponent(url);
    } else if (itemInArray(pathname, ["www.youtube.com", "youtu.be", "youtube.com"])) {
      return new YoutubeContentComponent(url);
    } else if (pathname.indexOf("tiktok") !== -1) {
      return new TikTokContentComponent(url);
    } else if (pathname.indexOf("instagram") !== -1) {
      return new InstagramContentComponent(url);
    } else {
      return new ContentComponent(url);
    }
  }
}
