import { AnyAction } from "redux";
import { home } from "../../constants/actionTypes";

interface InitialStateInterface {
  loading: boolean;
  error: string;
  data: any;
}

const initialState: InitialStateInterface = {
  loading: false,
  error: "",
  data: {},
};

const homeReducer = (state = initialState, action: AnyAction): InitialStateInterface => {
  const { type } = action;

  switch (type) {
    case home.GET_DATA:
      return {
        ...state,
        loading: true,
      };

    case home.GET_DATA_RECEIVED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default homeReducer;
