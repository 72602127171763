import React from "react";
import { Collapse } from "antd";
import styles from "./about.module.css";

export const About = () => {
  return (
    <div className={styles.aboutWrapper}>
      <div className={styles.innerContainer}>
        <h1 className={styles.about}>About</h1>
        <div className={styles.aboutContainer}>
          <Collapse defaultActiveKey={["1", "2"]} bordered={false} expandIconPosition="end">
            <Collapse.Panel
              showArrow={true}
              className={styles.collapsePanel}
              header="About the Eyes on Russia Project"
              key="1"
            >
              <div className={styles.collapseContent}>
                <p>
                  The Centre for Information Resilience (CIR) is an independent, non-profit social enterprise dedicated
                  to countering disinformation, exposing human rights abuses, and combating online behaviour harmful to
                  women and minorities.
                </p>
                <p>
                  In January 2022, CIR launched the Eyes on Russia project to collect and verify videos, photos,
                  satellite imagery or other media related to Russia’s invasion of Ukraine. Our objective was to provide
                  journalists, NGOs, policymakers and the public access to verified, trustworthy information.{" "}
                </p>
                <p>
                  Since then, the Eyes on Russia project has coordinated research by the wider OSINT (Open Source
                  Intelligence) community including Bellingcat and GeoConfirmed, with support from Advance Democracy,
                  Inc. The database of verified information is the collective product of that community.{" "}
                </p>
                <p>
                  Verified information is stored in a database and represented on the Russia-Ukraine Monitor Map with
                  one simple goal: to provide the world with timely and reliable information concerning the impact of
                  Russia’s invasion on Ukraine and its people.
                </p>
                <p>
                  Building on the original map that was developed and maintained with support from MapHub, CIR
                  collaborated with C4ADS to develop an updated vision for the Eyes on Russia Map. C4ADS, a
                  non-profit organization dedicated to defeating the illicit networks that threaten global peace and
                  security, joined the Eyes on Russia project with the aim to produce a new version of the map that
                  would expand the ways researchers can set search terms and interact with the map to advance their
                  analysis.{" "}
                </p>
                <p>
                  The sections below explain how to use the new map, describe the definitions used by the Eyes on Russia
                  project and the Eyes on Russia Map, and list the issues researchers should acknowledge when
                  interpreting data and handling graphic imagery.
                </p>
                <p>
                  For more information on how the Eyes on Russia project collects, verifies and investigates open source
                  information, watch the video{" "}
                  <a target="_blank" href="https://youtu.be/xaXz-EwUWOs">
                    Eyes on Russia &ndash; Mapping Russia’s War on Ukraine
                  </a>
                  .
                </p>
              </div>
            </Collapse.Panel>
            <Collapse.Panel className={styles.collapsePanel} header="How to Use the Map" key="2">
              <div className={styles.collapseContent}>
                <p>
                  The map draws on the database of videos, photos, satellite imagery or other media related to Russia’s
                  invasion of Ukraine that CIR’s Eyes on Russia project has been collecting and verifying since January
                  2022. CIR has verified the authenticity and location of all information contained in the database.
                </p>
                <p>You can select the data to be shown on the map in four ways:</p>
                <ul className={styles.list}>
                  <li>Select one or more Categories of event</li>
                  <li>Select one or more Sectors affected by the event</li>
                  <li>Select start and end dates for the time period being researched</li>
                  <li>Search for key words such as place names using the free text box</li>
                </ul>
                <p>
                  These search functions can be applied together or on their own. Descriptions of the Categories and
                  Sectors are provided below.
                </p>
                <p>
                  The map will present data complying with your search parameters. Each pin on the map represents a
                  piece of verified data. You can continue your analysis of the data:
                </p>
                <ul className={styles.list}>
                  <li>Hover your cursor over a pin to display a brief description of the data’s characteristics</li>
                  <li>
                    Click on a pin to display a preview box of the data and its characteristics including a link to the
                    original source of the data
                  </li>
                  <li>
                    Move the brackets along the timeline function at the bottom of the screen and press the “play”
                    button to view a time lapse of the emergence of the pins over the time period being researched
                  </li>
                  <li>
                    Increase or decrease the magnification of the map and search for only events within the map frame
                  </li>
                </ul>
                <p>
                  For an interactive tutorial on using the map, please refer to the video{" "}
                  <a target="_blank" href="https://youtu.be/xaXz-EwUWOs">
                    Eyes on Russia &ndash; Mapping Russia’s War on Ukraine
                  </a>
                  .
                </p>
              </div>
            </Collapse.Panel>
            <Collapse.Panel className={styles.collapsePanel} header="Definitions" key="3">
              <div className={styles.collapseContent}>
                <div>
                  <p className={styles.underlinedText}>Categories</p>
                  <ul className={styles.list}>
                    <li>Bombing or Explosion - Bombing or explosion seen in footage</li>
                    <li>Ground Battle - On ground action</li>
                    <li>Detention or Arrest – One or more individuals are detained or arrested</li>
                    <li>Civilian Casualty - Dead or injured people without uniform</li>
                    <li>Mass grave or Burial - Indications of graves or burials</li>
                    <li>Munitions - Visible munitions</li>
                    <li>
                      Civilian Infrastructure Damage - Signs of damage to civilian buildings or other types of civilian
                      infrastructure
                    </li>
                    <li>
                      Military Infrastructure Damage - Signs of damage to military buildings or other types of military
                      infrastructure
                    </li>
                    <li>Environmental Harm - Signs of environmental harm and /or impact</li>
                    <li>Russian Military Presence - Presence of Russian military vehicles, assets and/or troops</li>
                    <li>
                      Russian Military Losses - Signs of damage to Russian military vehicles, persons and/or assets
                    </li>
                    <li>
                      Russian Firing Positions - Signs of where Russian military assets have fired from or are seen
                      firing from
                    </li>
                    <li>
                      Russian Allies Movements or Losses - Presence or signs of damage of Russian military allies
                      vehicles, assets and/or troops
                    </li>
                  </ul>
                </div>
                <div>
                  <p className={styles.underlinedText}>Sectors</p>
                  <p>
                    Data is linked to the sectors listed below when the event has damaged or otherwise impacted
                    facilities, infrastructure or resources in those sectors.{" "}
                  </p>
                  <ul className={styles.list}>
                    <li>Commercial</li>
                    <li>Cultural</li>
                    <li>Education or Childcare</li>
                    <li>Food and Agriculture</li>
                    <li>Government</li>
                    <li>Healthcare</li>
                    <li>Humanitarian</li>
                    <li>Industrial</li>
                    <li>Infrastructure</li>
                    <li>Military</li>
                    <li>Religious</li>
                    <li>Residential</li>
                  </ul>
                </div>
              </div>
            </Collapse.Panel>
            <Collapse.Panel className={styles.collapsePanel} header="Warnings and disclaimers" key="4">
              <div className={styles.collapseContent}>
                <ul className={styles.list}>
                  <li>
                    The map and underlying database only contains information that CIR’s Eyes on Russia project was able
                    to collect and to verify. The database is not comprehensive of all events nor all information about
                    those events.
                  </li>
                  <li>
                    Each piece of data has been marked with a violence level to indicate the level of graphic content;
                    highly graphic content is not included in preview boxes.
                  </li>
                  <li>
                    CIR does not publish information that might put any individuals at risk. On occasion we may delay
                    publication to ensure the safety of civilians in Ukraine.
                  </li>
                  <li>
                    CIR only uses verified data when reporting on events; we are not responsible for claims about events
                    made on social media.
                  </li>
                  <li>
                    CIR’s process for verifying data is rigorous and involves several stages of review. However, if you
                    believe any data to be inaccurate please email osint@info-res.org explaining why you think the data
                    is not correct and we will review the data.
                  </li>
                  <li>
                    The credit box represents the organization or group that identified the information on social media
                    and submitted it to Eyes on Russia. While some information seen on this map is crowdsourced, our
                    methodology requires a rigorous review, ensuring all entries are checked by senior investigators at
                    Eyes on Russia.
                  </li>
                  <li>
                    The pins on this map link to verified videos, photos, or satellite imagery and has undergone
                    verification to identify exactly where it was filmed, and has been researched to verify date and
                    time to the best of our ability.
                  </li>
                </ul>
              </div>
            </Collapse.Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
};
