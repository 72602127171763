export interface ProxyArrayController {
  updateIndexes: (indexes: number[]) => void;
  getIndexes: () => number[];
}

const isArrayIndexKey = (key: string | symbol) => {
  return !isNaN(Number(key.toString()));
};

export const createProxyArray = <T>(data: T[], viewIndexes: number[]): [T[], ProxyArrayController] => {
  let currentIndexes = viewIndexes;
  const proxyArray: T[] = new Proxy<T[]>(data, {
    get(target: T[], key: string | symbol): any {
      if (key === "length") {
        return currentIndexes.length;
      }

      if (!isArrayIndexKey(key)) {
        // @ts-ignore
        return target[key];
      }

      const incomeIndex = Number(key);
      return target[currentIndexes[incomeIndex]];
    },
    ownKeys(): ArrayLike<string | symbol> {
      let keys: string[] = ["length"];
      return keys.concat(currentIndexes.map((item) => String(item)));
    },
  });

  const controller = {
    updateIndexes: (indexes: number[]) => {
      currentIndexes = indexes;
    },
    getIndexes: () => {
      return currentIndexes;
    },
  };

  return [proxyArray, controller];
};
