import React from "react";
import cn from "classnames";
import styles from "./menu.module.css";
import { Link, useLocation } from "react-router-dom";

const dataNavigations = [
  {
    path: "/",
    title: "Map",
  },
  {
    path: "/about",
    title: "About",
  },
];

export const Menu: React.FC = () => {
  const locations = useLocation();

  return (
    <div className="menu">
      <nav className={styles.navigation}>
        {dataNavigations.map((item) => {
          return (
            <Link
              key={item.title}
              to={item.path}
              className={cn(styles.navItem, locations.pathname === item.path && styles.activeNav)}
            >
              {item.title}
            </Link>
          );
        })}
      </nav>
    </div>
  );
};
