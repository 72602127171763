import React from "react";
import { Drawer, Modal } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons/lib";
import styles from "./adaptiveModal.module.css";

interface AdaptiveModalProps {
  open: boolean;
  close: () => void;
  children: React.ReactNode;
}
export const AdaptiveModal = ({ open, children, close }: AdaptiveModalProps) => {
  return (
    <>
      <Drawer
        className={styles.mobileModal}
        title={<span className={styles.mobileModalBackButton}>Back</span>}
        placement="left"
        open={open}
        destroyOnClose={true}
        width="100%"
        closeIcon={<div data-testid="close-drawer-btn"><ArrowLeftOutlined onClick={close} /></div>}
        bodyStyle={{ padding: 0 }}
        drawerStyle={{ background: "var(--grey-700)", padding: 0 }}
        headerStyle={{ background: "var(--grey-700)", borderBottom: "1px solid rgba(255, 255, 255, 0.3)" }}
      >
        {children}
      </Drawer>
      <div className={styles.desktopModal}>
        <Modal
          getContainer={false}
          title={null}
          closeIcon={null}
          onCancel={close}
          bodyStyle={{ padding: 0 }}
          open={open}
          footer={null}
        >
          {children}
        </Modal>
      </div>
    </>
  );
};

