export const categoryList = [
  "Russian Military Presence",
  "Bombing or Explosion",
  "Ground Battle",
  "Detention or Arrest",
  "Civilian Casualty",
  "Mass Grave or Burial",
  "Civilian Infrastructure Damage",
  "Military Infrastructure Damage",
  "Russian Military Losses",
  "Munitions",
  "Russian Firing Positions",
  "Russian allies movements or losses",
  "Environmental Harm",
].sort();

export const sectorAffected = [
  "Residential",
  "Industrial",
  "Government",
  "Healthcare",
  "Education or childcare",
  "Military",
  "Commercial",
  "Religious",
  "Cultural",
  "Humanitarian",
  "Infrastructure",
  "Food and agriculture",
].sort();

export const categoryColorRange = [
  "#0300ff",
  "#CCFFFF",
  "#FFFF33",
  "#CCCC99",
  "#CCCCCC",
  "#CCCCFF",
  "#FFCCFF",
  "#FFCCCC",
  "#CC9966",
  "#CC9999",
  "#FF99CC",
  "#CC66FF",
  "#CC3366",
];
