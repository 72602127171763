import MapHubDefault from "../../../images/map-icons/map-hub-default.png";
import { MapInfo } from "../components/mapStyleChanger";
import { AnyAction } from "redux";

interface KeplerMapStateInterface {
  ready: boolean;
  entryReady: boolean;
  dateRange: [number, number] | null;
  currentMap: MapInfo;
}

const initialState: KeplerMapStateInterface = {
  ready: false,
  entryReady: false,
  dateRange: null,
  currentMap: { key: 0, id: "0", name: "Default", img: MapHubDefault, url: "mapbox://styles/mapbox/light-v10" },
};

export const keplerMapStateReducer = (state: KeplerMapStateInterface = initialState, action: AnyAction) => {
  switch (action.type) {
    case "CHANGE_MAP_STYLE":
      return { ...state, currentMap: action.payload };
    default:
      return state;
  }
};

export const changeMapStyleActionCreator = (mapStyle: MapInfo) => {
  return {
    type: "CHANGE_MAP_STYLE",
    payload: mapStyle,
  };
};
